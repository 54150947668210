import { getEnv } from '@antegma/accallio-cloud-react-components/lib/app/util/env'

type TEnv = {
  SDK_API_KEY: string
}

const Hootsuite = getEnv<TEnv>({
  SDK_API_KEY: process.env.REACT_APP_HOOTSUITE_SDK_API_KEY || 'Failed',
})

export default Hootsuite
