/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction } from 'redux'
import { TaskDetails } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'

const actions = {
  CHANGE_PROJECT_TASK_ENABLED: 'libraries/CHANGE_PROJECT_TASK_ENABLED',
  CHANGE_PROJECT: 'libraries/CHANGE_PROJECT',
  CHANGE_TYPE: 'libraries/CHANGE_TYPE',
  CREATE_TASK: 'libraries/CREATE_TASK',
  LOAD_MESSAGE: 'libraries/LOAD_MESSAGE',
  LOAD_PROJECTS: 'libraries/LOAD_PROJECTS',
  LOAD_USERS: 'libraries/LOAD_USERS',
  SET_STATE: 'libraries/SET_STATE',
  LOAD_STATE: 'libraries/LOAD_STATE',
  GET_STATE: 'libraries/GET_STATE',
}
export default actions

export const setLoading = (loading: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      loading,
    },
  }
}

export const changeProjectTaskEnabled = (projectTaskEnabled: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      projectTaskEnabled,
      selectedProjectID: undefined,
      assignedToID: undefined,
    },
  }
}

export const changeProject = (selectedProjectID: string): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      selectedProjectID,
    },
  }
}

export const changeType = (type: string): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      type,
    },
  }
}

export const changeAssignedTo = (assignedToID: string): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      assignedToID,
    },
  }
}

export const changePlannedCompletionDate = (plannedCompletionDate: Date): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      plannedCompletionDate,
    },
  }
}

export const createTask = (task: TaskDetails): AnyAction => {
  return {
    type: actions.CREATE_TASK,
    payload: {
      task,
    },
  }
}

export const loadMessage = (messageId: string): AnyAction => {
  return {
    type: actions.LOAD_MESSAGE,
    payload: {
      messageId,
    },
  }
}

export const loadProjects = (search: string): AnyAction => {
  return {
    type: actions.LOAD_PROJECTS,
    payload: {
      search,
    },
  }
}

export const loadUsers = (search: string): AnyAction => {
  return {
    type: actions.LOAD_USERS,
    payload: {
      search,
    },
  }
}

export const setState = (payload: any): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}
