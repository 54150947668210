/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
/* eslint-disable dot-notation */
import { useContext } from 'react'
import { all, put, takeEvery, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import Axios from 'axios'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import { ApplicationContext } from '@antegma/accallio-cloud-react-components/lib/app/context'
import { TaskDetails } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import messageApi from '~/services/Message'
import taskApi from '~/services/Task'
import projectApi from '~/services/Project'
import userApi from '~/services/User'
import actions, { setLoading } from './actions'
import Hootsuite from '~/services/Hootsuite'

function* LOAD_MESSAGE({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  yield put(setLoading(true))

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }
  const messageResult = yield messageApi.getMessage(payload.messageId, options)

  yield put({
    type: actions.SET_STATE,
    payload: {
      messageName: messageResult.data.name,
      messageDescription: messageResult.data.description,
      loading: false,
    },
  })
}

function* LOAD_PROJECTS({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { search } = payload

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }
  const projectsResult = yield projectApi.getProjects(search, options)

  yield put({
    type: actions.SET_STATE,
    payload: {
      projects: projectsResult.data.data,
    },
  })
}

function* LOAD_USERS({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { search } = payload

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }
  const usersResult = yield userApi.getUsers(search, options)

  yield put({
    type: actions.SET_STATE,
    payload: {
      users: usersResult.data.data,
    },
  })
}

function* LOAD_STATE() {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  console.log('LOAD_STATE (collections)', accessToken)
}

function* CREATE_TASK({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  yield put(setLoading(true))

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }
  yield taskApi.createTask(payload.task, options)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })

  const ctx = useContext(ApplicationContext)
  const sdkApiKey = Hootsuite.SDK_API_KEY
  if (ctx.extraQueryParams !== undefined) {
    const placementId = ctx.extraQueryParams.hootsuitePlacementId

    window['hsp']['closeCustomPopup']({
      sdkApiKey,
      placementId,
    })

    window.parent.frames[`${sdkApiKey}_${placementId}`].location.reload()
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STATE, LOAD_STATE),
    takeEvery(actions.CREATE_TASK, CREATE_TASK),
    takeEvery(actions.LOAD_MESSAGE, LOAD_MESSAGE),
    takeEvery(actions.LOAD_PROJECTS, LOAD_PROJECTS),
    takeEvery(actions.LOAD_USERS, LOAD_USERS),
  ])
}
